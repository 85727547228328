export const descriptions_module = {
    1: <>In this module, we will introduce you to the concept of carbon neutrality and its significance
                                in mitigating the impactof climate changeand its impact on the environment, society, and
                                economy. Within the framework of this module, we will touch on the following
                                lessons: <br/><br/>
                                ● Lesson 1: Introduction to carbon neutrality and climate change <br/>
                                ● Lesson 2: Understanding Carbon Neutrality <br/>
                                ● Lesson 3: The Importance of Carbon Neutrality <br/>
                                ● Lesson 4: Implementing Carbon Neutrality in Organizations Climate change is one of the biggest challenges
                                of our time, and its effects are being felt across the globe.<br/>
                                The increase in global temperatures
                                caused by the emission of greenhouse gases, primarily carbon dioxide, is causing a wide
                                range of problems, from rising sea levels to more extreme weather events. Carbon
                                neutrality
                                refers to the balance between the amount of carbon emitted and the amount removed from the
                                atmosphere. Achieving carbon neutrality is essential in reducing the impact of climate
                                change. In this module, we will explore the importance of carbon neutrality and its
                                role in
                                addressing the global climate crisis. We will examine successful carbon neutrality
                                initiatives in various sectors, and discuss the steps that individuals, organizations, and
                                governments can take to contribute to a carbon-neutral future.</>,


    2: <>In this module, we will focus on understanding the concept of carbon footprint and explore ways to reduce greenhouse gas emissions. We will cover a range of topics, including measuring carbon footprint, identifying emission sources, and developing emissions reduction strategies.
<br/><br/>
 • Lesson 1: Understanding Carbon Footprint<br/>
 • Lesson 2: Methods of Measuring Carbon Footprint<br/>
 • Lesson 3: Strategies for Emissions Reduction</>   ,

    3: <>In this module, we will explore the concept of carbon markets and how they can play a role in reducing greenhouse gas emissions. We will also discuss the use of carbon offsets as a strategy to mitigate emissions, and the challenges and controversies associated with this approach.
<br/><br/>
 • Lesson 1: What are Carbon Markets?<br/>
 • Lesson 2: What is carbon credit?<br/>
 • Lesson 3: VCM: main trends and challenges<br/>
 • Lesson 4: Emissions Trading Systems (ETSs)</>
}