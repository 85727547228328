import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import logo3 from '../../static/img/logo-3.svg';
import burger2 from '../../static/img/burger-2.png';
import { url_back } from '../Api/vars';

function Forgot() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleEmailChange = (event) => {
        setErrorMessage('')
        setEmail(event.target.value);
    };

    const handleForgotPassword = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            // alert('Please enter correct email.');
            setErrorMessage('Please enter correct email.')
            return;
        }

        try {
            const response = await fetch(url_back + '/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email })
            });
            console.log('forgot res', response)
            if (response.ok) {
                alert('На вашу электронную почту отправлен новый пароль.');
            } else {
                alert('Произошла ошибка. Попробуйте еще раз.');
            }
        } catch (error) {
            console.error('Ошибка:', error);
            alert('Произошла ошибка. Попробуйте еще раз.');
        }
    };

    return (
        <div>
            <header className="header header-user">
                <div className="container">
                    <div className="header-contant">
                        <div className="header-logo">
                            <a className="logo-1 logo-user" href="index.html"><img src={logo3} alt="logo" /></a>
                        </div>
                        <div className="header-nav">

                        </div>
                        <div className="header-button">
                            <div className="button button-header button-header-user" onClick={() => navigate('/login')}>Log in</div>
                        </div>
                        <div className="blok-menu-mobile blok-menu-mobile-user">
                        </div>
                    </div>
                </div>
            </header>

            <section className="section-1-user">
                <div className="container">
                    <div className="section-1-user-contant">
                        <form className="form-user-1">
                            <p className="user-form-title">Forgot password?</p>
                            <div style={{marginTop: '30px'}} className="one-ryad">
                                <input className="pole pole-user" name="email" type="email" placeholder="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                <p className="user-form-text">Please enter the email address that you used to create your account.
                                    If an account
                                    is linked to your email address, you will receive an email with the next steps.</p>
                            </div>
                            <div style={{marginTop: '20px'}} onClick={handleForgotPassword} className="button button-1 button-popup-1">Recover Password</div>

                            {
                                errorMessage && <div style={{ width: '100%', textAlign: 'center', marginTop: '10px', fontSize: '12px', color: 'red' }}>{errorMessage}</div>
                            }

                            <a className="close-popup" href="#"><img src={burger2} alt="Burger" /></a>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Forgot;



