import { url_back } from "./vars";

async function getCourse(email) {
    try {
        const response = await fetch(url_back + '/getcourse', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const modules = await response.json();
            console.log('modules', modules);
            
            return modules.modules;
        } else {
            console.log('Error getcourse');
            const errorData = await response.json();
            console.error('Error getcourse message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

async function setViewLesson(user_id, lesson_number, module_number) {
    try {
        const response = await fetch(url_back + '/setviewlesson', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user_id, lesson_number, module_number }),
        });

        if (response.ok) {
            const res = await response.json();
            console.log('setViewLesson', res);
            
            return res;
        } else {
            console.log('Error setviewlesson');
            const errorData = await response.json();
            console.error('Error setviewlesson message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

async function getUsers(user_id) {
    try {
        const response = await fetch(url_back + '/getusers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id }),
        });

        if (response.ok) {
            const res = await response.json();
            console.log('getUsers', res);
            
            return res.users;
        } else {
            console.log('Error setviewlesson');
            const errorData = await response.json();
            console.error('Error setviewlesson message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

async function updateUserData(email, first_name, last_name, user_id) {
    try {
        const response = await fetch(url_back + '/saveuserdata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, first_name, last_name, user_id }),
        });

        if (response.ok) {
            const res = await response.json();
            console.log('User data updated successfully', res);
            return true;
        } else {
            console.log('Error updating user data');
            const errorData = await response.json();
            console.error('Error updating user data message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

async function testStripeHook() {
    try {
        const response = await fetch('https://learn-carbon.academy:443/stripehook', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ d: 7 }),
        });

        if (response.ok) {
            const res = await response.json();
            console.log('User data updated successfully', res);
            return true;
        } else {
            console.log('Error updating user data');
            const errorData = await response.json();
            console.error('Error updating user data message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}


export {getCourse, setViewLesson, getUsers, updateUserData, testStripeHook};