import React, { useState } from 'react';
import FAQQuestionsIcon1 from '../../static/img/faq-quesions-icon-1.svg';
import FAQQuestionsIcon2 from '../../static/img/faq-quesions-icon-2.svg';
import progress1 from "../../static/img/class-progress-icon-1.svg"
import ProgressIcon2 from '../../static/img/class-progress-icon-2.svg';
import ProgressIcon3 from '../../static/img/class-progress-icon-3.svg';
import ProgressIcon4 from '../../static/img/class-progress-icon-4.svg';
import Module from './widgets/Module';
import { useDispatch, useSelector } from 'react-redux';
import { selectModules, setSelectedLesson } from '../Login/loginSlice';
import ContactUs from '../ContactUs';

function Support( {setSelectedSection} ) {
    const dispatch = useDispatch()
    const modules = useSelector(selectModules)
    const progressIcons = {
        titleIcon: ProgressIcon3,
        openIcon: progress1,
        closedIcon: ProgressIcon2,
        lessonIcon: ProgressIcon3,
        emptyIcon: ProgressIcon4
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const [showFormContactUs, setShowFormContactUs] = useState(false);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleOpenChat = () => {
        console.log('handleOpenChat')
        if (window.jivo_api) {
            window.jivo_api.open();
        } else {
            console.error('JivoChat API is not loaded yet.');
        }
    };

    // const handleClickAskQuestion = () => setShowFormContactUs(!showFormContactUs)
    const handleClickAskQuestion = () => handleOpenChat()

    const getIconSrc = (index) => {
        return activeIndex === index ? FAQQuestionsIcon1 : FAQQuestionsIcon2;
    };

    const handleLessonClick = (videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid) => {
        console.log('handleLessonClick', LessonNumber, ModuleNumber, gid)
        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        setSelectedSection('classroom')
        // dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        // lesson_number = LessonNumber
        // module_number = ModuleNumber
    };

    return (
        <>
            <div className="section-class-center">
                <div className="section-class-center-vnutr">
                    <div className="section-class-faq-contant">
                        <p className="faq-title">FAQ</p>
                        <p className="faq-descriptions">(Answers to your most frequently asked questions about Carbon
                            Learn course)</p>
                        <div className="faq-quesions-element" id="faq-quesions-element-1">
                            <div className="faq-quesions-title" onClick={() => toggleFAQ(1)}>
                                Who is it for?
                                {/* <img src={FAQQuestionsIcon1} alt="quesions" /> */}
                                <img src={getIconSrc(1)} alt="quesions" />
                                
                            </div>
                            <div className="faq-quesions-body" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
                                This course is ideal for individuals who work in sustainability, environmental or
                                energy management and also for those who want to understand what the road to carbon
                                neutrality. There are no eligibility requirements.
                            </div>
                        </div>
                        <div className="faq-quesions-element" id="faq-quesions-element-2">
                            <div className="faq-quesions-title" id="faq-quesions-element-2" onClick={() => toggleFAQ(2)}>
                                How long with the course take me to complete?
                                <img src={getIconSrc(2)} alt="quesions" />
                            </div>
                            <div className="faq-quesions-body" style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
                                We expect each module to take between 60 and 100 minutes, about 3.5 hours in total.
                            </div>
                        </div>
                        <div className="faq-quesions-element" id="faq-quesions-element-3">
                            <div className="faq-quesions-title" id="faq-quesions-element-3" onClick={() => toggleFAQ(3)}>
                                What is the course of study like?
                                <img src={getIconSrc(3)} alt="quesions" />
                            </div>
                            <div className="faq-quesions-body" style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
                                Participants can access the course through our online learning platform, where they can complete lessons at their own pace. They can also access additional resources, including case studies, research papers and practical tools that can help them apply the knowledge and skills they have learned in their organizations or daily lives.
                            </div>
                        </div>
                        <div onClick={handleClickAskQuestion} className="button button-header open-popup">Ask a question</div>
                    </div>
                </div>
            </div>
            <div className="section-class-right section-class-right-settings">
                <p className="section-class-right-title">Progress Lesson</p>
                {modules.map(module => (
                    <Module
                        key={module.id}
                        title={`Module ${module.number}: ${module.name}`}
                        lessons={module.lessons.map(lesson => ({
                            name: lesson.name,
                            videoId: lesson.video_id,
                            videoH: lesson.video_h,
                            number: lesson.number,
                            gid: lesson.gid
                        }))}
                        module_number={module.number}
                        progressIcons={progressIcons}
                        onLessonClick={handleLessonClick}
                    />
                ))}
            </div>
            {showFormContactUs && <ContactUs handleClickAskQuestion={handleClickAskQuestion} />}
        </>
    );
}

export default Support;
