import { url_back } from "./vars";

async function sendContactForm(name, email, message) {
    try {
        const response = await fetch(url_back + '/sendcontactform', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, message }),
        });

        if (response.ok) {
            console.log('Contact form sent successfully');
            return true;
        } else {
            console.log('Error sending contact form');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}


export {sendContactForm};