import React, { useEffect, useState } from 'react';
import ClassRoom from './ClassRoom'; // Импортируем компонент ClassRoom
import Support from './Support';
import Settings from './Settings';
import logo3 from "../../static/img/logo-3.svg"
import icon_mass from "../../static/img/icon-mass.svg"
import burger3 from "../../static/img/burger-3.svg"
import burger2user from "../../static/img/burger-2-user.svg"
import user_image from "../../static/img/user-image.png"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId, setModules, setSelectedLesson } from '../Login/loginSlice';
import { getCourse } from '../Api/course';
import AdminRoom from './AdminRoom';

function Person() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [selectedSection, setSelectedSection] = useState('dashboard');
    const userId = useSelector(selectUserId)

    // const modules = useSelector(selectModules)

    function toggleVisibility() {
        let element = document.getElementById('mobile-conteiner');
        let element1 = document.getElementById('burger-2');
        let element2 = document.getElementById('burger-1');
        if (element.style.display === 'none') {
            element.style.display = 'flex';
        } else {
            element.style.display = 'none';
        };
        if (element1.style.display === 'none') {
            element1.style.display = 'flex';
        } else {
            element1.style.display = 'none';
        };
        if (element2.style.display === 'flex') {
            element2.style.display = 'none';
        } else {
            element2.style.display = 'flex';
        }
    };

    function toggleVisibility2() {
        let element = document.getElementById('mobile-conteiner');
        let element1 = document.getElementById('burger-2');
        let element2 = document.getElementById('burger-1');
        if (element.style.display === 'flex') {
            element.style.display = 'none';
        } else {
            element.style.display = 'flex';
        };
        if (element1.style.display === 'flex') {
            element1.style.display = 'none';
        } else {
            element1.style.display = 'flex';
        };
        if (element2.style.display === 'none') {
            element2.style.display = 'flex';
        } else {
            element2.style.display = 'none';
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                // console.log('fetch', user_id)
                const modulesData = await getCourse();
                if (modulesData) {
                    dispatch(setModules(modulesData));
                    // const selectedLesson = {
                    //     videoId: modulesData[0].lessons[0].video_id,
                    //     videoH: modulesData[0].lessons[0].video_h,
                    //     moduleName: modulesData[0].name,
                    //     lessonName: modulesData[0].lessons[0].name,
                    //     LessonNumber: modulesData[0].lessons[0].number,
                    //     ModuleNumber: modulesData[0].number,
                    //     gid: modulesData[0].lessons[0].gid
                    // };
                    const selectedLesson = {
                        videoId: 0,
                        videoH: 0,
                        moduleName: modulesData[0].name,
                        lessonName: 0,
                        LessonNumber: 0,
                        ModuleNumber: modulesData[0].number,
                        gid: ''
                    };
                    dispatch(setSelectedLesson(selectedLesson))
                }
            } catch (error) {
                console.error('Error fetching modules:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <header className="header header-user">
                <div className="container">
                    <div className="header-contant">
                        <div className="header-logo">
                            <a className="logo-1 logo-user" href="/"><img src={logo3} alt="logo" /></a>
                        </div>
                        <div className="header-nav">

                        </div>
                        <div className="header-button header-button-user">
                            {/* <a className="user-icon" href="user-10.html"><img src={icon_mass} alt="massege" /></a> */}
                            {/* <img className="user-image" src={user_image} alt="massege" /> */}
                        </div>
                        <div className="blok-menu-mobile">
                            <img onClick={() => toggleVisibility()} className="burger-1" id="burger-1" src={burger3}
                                alt="burger" />
                            <img onClick={() => toggleVisibility2()} className="burger-2" id="burger-2" src={burger2user}
                                alt="burger" />
                        </div>
                    </div>
                </div>
                <div className="mobile-conteiner mobile-conteiner-user" id="mobile-conteiner">
                    <div className="mobile-conteiner-user-vnutr">
                        <div className="section-class-left">
                            <ul className="class-menu">
                                <li>
                                    <div onClick={() => setSelectedSection('dashboard')}
                                        className="section-class-left-text section-class-left-text-0">Dashboard
                                    </div>
                                </li>
                                <li>
                                    <div onClick={() => setSelectedSection('classroom')}
                                        className="section-class-left-text section-class-left-text-1">Classroom</div>
                                </li>
                                <li>
                                    <div onClick={() => setSelectedSection('settings')}
                                        className="section-class-left-text section-class-left-text-2">Settings
                                    </div>
                                </li>
                                <li>
                                    <div onClick={() => setSelectedSection('support')}
                                        className="section-class-left-text section-class-left-text-3">Support & Help
                                    </div>
                                </li>
                                <li>
                                    <div onClick={() => navigate('/login')} className="section-class-left-text section-class-left-text-4">Log Out
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section className="section-class">
                {userId !== 14 &&
                    <div className="container">
                        <div className="section-class-contant">
                            <div className="section-class-left section-class-left-pc">
                                <ul className="class-menu class-menu-pc">
                                    <li>
                                        <div className="section-class-left-text section-class-left-text-0" onClick={() => setSelectedSection('dashboard')}>
                                            Dashboard
                                        </div>
                                    </li>
                                    <li><div onClick={() => setSelectedSection('classroom')}
                                        className="section-class-left-text section-class-left-text-1">Classroom</div>
                                    </li>
                                    <li>
                                        <div onClick={() => setSelectedSection('settings')}
                                            className="section-class-left-text section-class-left-text-2">Settings</div></li>
                                    <li>
                                        <div onClick={() => setSelectedSection('support')}
                                            className="section-class-left-text section-class-left-text-3">
                                            Support & Help
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={() => navigate('/login')} className="section-class-left-text section-class-left-text-4">
                                            Log Out
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            {selectedSection === 'dashboard' &&
                                <div>
                                    {/* <div className="section-class-center">
                                        Dashboard
                                    </div> */}
                                    <div className="section-class-center">
                                        <div className="section-class-center-vnutr">
                                            <h2 className="h2">Welcome to Carbon Learn!</h2>
                                            <div className="section-2-items">
                                                <div className=" section-2-item-1">
                                                    <p className="section-2-text">
                                                        Dear User,<br /><br />
                                                        Welcome to the Carbon Learn course. We are thrilled to have you on board as you embark on your journey towards understanding and achieving carbon neutrality.<br /><br />
                                                    </p>
                                                    <h3 className="h3">Navigation Overview</h3>
                                                    <ul className="section-2-text">
                                                        <li><span style={{ color: '#00533A', cursor: 'pointer' }}  onClick={() => setSelectedSection('dashboard')}>Dashboard:</span> This is your welcome page.</li>
                                                        <li><span style={{ color: '#00533A', cursor: 'pointer' }}  onClick={() => setSelectedSection('classroom')}>Classroom:</span> Here you will find all your learning materials, including videos and presentations. You can start your lessons and track your progress. Access it here.</li>
                                                        <li><span style={{ color: '#00533A', cursor: 'pointer' }}  onClick={() => setSelectedSection('settings')}>Settings:</span> Manage your account settings and preferences.</li>
                                                        <li><span style={{ color: '#00533A', cursor: 'pointer' }}  onClick={() => setSelectedSection('support')}>Support & Help:</span> Get assistance and find answers to your questions.</li>
                                                        <li><span style={{ color: '#00533A', cursor: 'pointer' }}  onClick={() => navigate('/login')}>Log Out:</span> Safely log out of your account.</li>
                                                    </ul>
                                                    <p className="section-2-text">
                                                        We wish you the best of luck in your learning journey. Should you have any questions or need support, please do not hesitate to reach out.<br /><br />
                                                        Happy learning!<br /><br />
                                                        Best regards,<br />
                                                        The Learn Carbon Academy Team
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {selectedSection === 'classroom' && <ClassRoom />}
                            {selectedSection === 'support' && <Support setSelectedSection={setSelectedSection}/>}
                            {selectedSection === 'settings' && <Settings setSelectedSection={setSelectedSection}/>}
                        </div>
                    </div>
                }
                {
                    userId === 14 &&
                    <AdminRoom />
                }
            </section>
        </div>
    );
}

export default Person;

