import React, { useEffect, useState } from 'react';
import popupIcon from '../static/img/burger-2.png';
import { sendContactForm } from './Api/contact';

const ContactUs = ({ handleClickAskQuestion }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [showResSend, setShowResSend] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, message } = formData;
        const success = await sendContactForm(name, email, message); // Отправка данных на сервер
        if (success) {
            // Действия после успешной отправки данных
            setShowResSend(true)
            console.log('Form submitted successfully');
        } else {
            // Действия в случае ошибки при отправке данных
            console.error('Error submitting form');
        }
    };

    const initEvents = () => {
        setShowResSend(false)
        let popupBg = document.querySelector('.popup__bg'); // Фон попап окна
        let popup = document.querySelector('.popup'); // Само окно
        let openPopupButtons = document.querySelectorAll('.open-popup'); // Кнопки для показа окна
        let closePopupButton = document.querySelector('.close-popup'); // Кнопка для скрытия окна

        // openPopupButtons.forEach((button) => { // Перебираем все кнопки
        //     button.addEventListener('click', (e) => { // Для каждой вешаем обработчик событий на клик
        // e.preventDefault(); // Предотвращаем дефолтное поведение браузера
        popupBg.classList.add('active'); // Добавляем класс 'active' для фона
        popup.classList.add('active'); // И для самого окна
        // })
        // });

        closePopupButton.addEventListener('click', () => { // Вешаем обработчик на крестик
            popupBg.classList.remove('active'); // Убираем активный класс с фона
            popup.classList.remove('active'); // И с окна
        });

        document.addEventListener('click', (e) => { // Вешаем обработчик на весь документ
            if (e.target === popupBg) { // Если цель клика - фот, то:
                popupBg.classList.remove('active'); // Убираем активный класс с фона
                popup.classList.remove('active'); // И с окна
            }
        });
    }

    useEffect(() => {
        setTimeout(initEvents, 100)
    }, []);

    return (
        <div className="popup__bg" id="popup1">
            <form className="popup" onSubmit={handleSubmit}>
                <p className="popup-title">Contact us</p>
                <div className="one-ryad">
                    <input
                        className="pole"
                        name="name"
                        type="text"
                        placeholder="Full name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        className="pole"
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <textarea
                        className="pole"
                        name="message"
                        rows="3"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                </div>
                {!showResSend && <button type="submit" className="button button-1 button-popup-1">Submit</button>}
                {showResSend && <div onClick={handleClickAskQuestion} className="button button-1 button-popup-1">Success</div>}
                
                <div onClick={handleClickAskQuestion} className="close-popup"><img src={popupIcon} alt="close"></img></div>
            </form>
        </div>
    );
};

export default ContactUs;
