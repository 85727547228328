import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLessonNumber, selectModuleNumber } from '../../Login/loginSlice';

function Module({ title, lessons, module_number, progressIcons, onLessonClick  }) {
  const l_n = useSelector(selectLessonNumber)
  const m_n = useSelector(selectModuleNumber)

  const [isBodyOpen, setIsBodyOpen] = useState(false);

  const toggleBody = () => {
    setIsBodyOpen(!isBodyOpen);
    onLessonClick(0, 0, title, 0, 0, module_number, '')
  };

  return (
    <div className="class-progress-modul" id="progress-modul-1">
      <div className="class-progress-modul-title">
        <div className="class-progress-modul-title-left" onClick={toggleBody} style={{ cursor: 'pointer'}}>
          <strong>{title}</strong>
          <img src={progressIcons.titleIcon} alt="icon" />
        </div>
        <div className="class-progress-modul-title-right" onClick={toggleBody}>
          <img className="progress-open" src={isBodyOpen ? progressIcons.openIcon : progressIcons.closedIcon} alt="icon" />
        </div>
      </div>
      {isBodyOpen && (
        <div className="class-progress-modul-body">
          {lessons.map((lesson, index) => (
            <div className="modul-body-element" key={index} style={{cursor: 'pointer'}}
              onClick={() => onLessonClick(lesson.videoId, lesson.videoH, title, lesson.name, lesson.number, module_number, lesson.gid)}
            >
              <p className="class-progress-modul-body-text">
                {`Lesson ${lesson.number}: ${lesson.name}`}
              </p>
              <div className="class-progress-modul-body-line-1"></div>
              {/* <img className="progress-icon" src={progressIcons.lessonIcon} alt="icon" /> */}
              { 
                (module_number === m_n && lesson.number <= l_n) || (module_number < m_n) ? 
                <img className="progress-icon" src={progressIcons.lessonIcon} alt="icon" />:
                <img className="progress-icon" src={progressIcons.emptyIcon} alt="icon" />
              
              }
              
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Module;
