import React, { useState } from 'react';
import { sendConfirmationEmail, sendVerificationCode } from '../Api/confirm';
import { useNavigate } from 'react-router-dom';

import logo3 from '../../static/img/logo-3.svg';
import burgerImage from '../../static/img/burger-2.png';

const invalid_code_text = 'Invalid code, try again'
const resend_code_text = 'Code has been sent'

function ConfirmEmail() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    const [showInvalidCodeText, setShowInvalidCodeText] = useState('');

    const handleConfirmEmail = async () => {
        if (email.trim() === '') {
            alert('Enter your email address')
            return
        }
        try {
            await sendConfirmationEmail(email);
            setIsEmailConfirmed(true);
        } catch (error) {
            console.error('Error confirming email:', error);
        }
    };

    const handleVerifyCode = async () => {
        // Отправить запрос на бэкенд для проверки введенного пользователем кода
        // Если код верный, выполнить необходимые действия
        try {
            // Фиктивная функция verifyConfirmationCode для проверки кода на бэкенде
            const data = await sendVerificationCode(email, code);
            if (data.res) {
                navigate('/login')
                window.open(data.paymentLink.url, '_blank');
            } else {
                // Если код не подтвержден, вывести сообщение об ошибке или выполнить другие действия
                console.log('Invalid confirmation code');
                setShowInvalidCodeText(invalid_code_text)
                setTimeout(()=>{setShowInvalidCodeText('')}, 5000)
            }
        } catch (error) {
            console.error('Error verifying confirmation code:', error);
        }
    };

    const handleBackClick = () => {
        setIsEmailConfirmed(false);
        // Добавьте здесь логику для обработки нажатия на ссылку "BACK"
        console.log('Go back');
    };

    const handleResendCodeClick = () => {
        // Добавьте здесь логику для обработки нажатия на ссылку "SEND CODE AGAIN"
        console.log('Resend code');
        setShowInvalidCodeText(resend_code_text)
        setTimeout(()=>{setShowInvalidCodeText('')}, 5000)
    };

    return (
        <section className="section-class">
            <div className="container">
                {/* <div className="section-oplata-contant">
                    {!isEmailConfirmed ? (
                        <div>
                            <span className="h2-big h2-big-oplata"> Email verification</span>
                            <h1 className="h2 h2-s3 h2-oplata"> Email verification</h1>
                            <div className="oplata-contant-box">
                                <p className="oplata-contant-description">Enter your email address to receive a confirmation code.</p>
                                <input className="pole-settings pole-oplata pole-oplata-1" type="email" value={email} 
                                    onChange={(e) => setEmail(e.target.value)} placeholder="Email" 
                                />
                                <div className="button button-header button-oplata" onClick={handleConfirmEmail}>Confirm</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span className="h2-big h2-big-oplata">Verify Code</span>
                            <h1 className="h2 h2-s3 h2-oplata">Verify Code</h1>
                            <div className="oplata-contant-box">
                                <p className="oplata-contant-description">A confirmation code has been sent to your email address. Enter the code below to confirm your email.</p>
                                <input className="pole-settings pole-oplata pole-oplata-1" type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Confirmation Code" />
                                <button className="button button-header button-oplata" onClick={handleVerifyCode}>Verify</button>
                            </div>
                        </div>
                    )}
                </div> */}

                <section className="section-1-user">
                    <div className="container">
                        <div className="section-1-user-contant">
                            {!isEmailConfirmed &&
                                <div className="form-user-1">
                                    <p className="user-form-title">Email verification</p>
                                    <div className="one-ryad">
                                        <input className="pole pole-user" name="email" type="email" placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {/* <input className="pole pole-user" name="password" type="text" placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    /> */}
                                    </div>
                                    {/* <a className="button button-1 button-popup-1" href="user-dashboard.html">Login</a> */}
                                    <button className="button button-1 button-popup-1" onClick={handleConfirmEmail}>Confirm</button>
                                    <a className="close-popup" href="user-dashboard.html"><img src={burgerImage} alt="close" /></a>
                                </div>
                            }
                            {isEmailConfirmed &&
                                <div className="form-user-1" style={{paddingBottom: 0}}>
                                    <p className="user-form-title">Verify Code</p>
                                    <div className="one-ryad">
                                        <input className="pole pole-user" name="email" type="email" placeholder="Code"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                        <div style={{height: '15px'}}>{(showInvalidCodeText !== '') && <div style={{fontSize: '12px'}}>{showInvalidCodeText}</div>}</div>
                                        {/* <input className="pole pole-user" name="password" type="text" placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    /> */}
                                    </div>
                                    
                                    <button className="button button-1 button-popup-1" onClick={handleVerifyCode}>Verify</button>
                                    <a className="close-popup" href="user-dashboard.html"><img src={burgerImage} alt="close" /></a>
                                    <div>
                                        <p className='label-check-oplata'>
                                            We have sent a confirmation code to your email. Sometimes emails may end up in the spam folder. If you do not receive the email within 10 minutes, please check your spam folder.
                                            You can change your email by going <span style={{ color: '#00533A', cursor: 'pointer' }} onClick={handleBackClick}>BACK</span>, or resend the code by clicking the <span style={{ color: '#00533A', cursor: 'pointer' }} onClick={handleResendCodeClick}>SEND CODE AGAIN</span>.
                                        </p>
                                    </div>
                                </div>
                            }
                            {/* <div onClick={handleConfirmEmail} className="user-link">Confirm</div> */}
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}


export default ConfirmEmail;
