import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import logo3 from '../../static/img/logo-3.svg';
import burgerImage from '../../static/img/burger-2.png';
import { url_back } from '../Api/vars';
import { useDispatch } from 'react-redux';
import { setEmail, setFirstName, setLastName, setLessonNumber, setModuleNumber, setUserId } from './loginSlice';

function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        console.log('handleSubmit')
        const { email, password } = formData;
        const encryptedPassword = md5(password); // Шифрование пароля с помощью MD5

        try {
            // Отправляем запрос на сервер для аутентификации
            const response = await fetch(url_back + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password: encryptedPassword }),
            });

            if (response.ok) {
                // Вход успешный, переходим на экран Person
                const res = await response.json()
                console.log('good', res.user)
                dispatch(setUserId(res.user.id))
                dispatch(setLessonNumber(res.user.lesson))
                dispatch(setModuleNumber(res.user.module))
                dispatch(setFirstName(res.user.first_name))
                dispatch(setLastName(res.user.last_name))
                dispatch(setEmail(res.user.email))
                navigate('/person');
                setErrorMessage('')
            } else {
                console.log('err',)
                // В случае неудачи выводим сообщение об ошибке
                const errorData = await response.json();
                setErrorMessage('Incorrect email or password. Please check your login credentials.');
            }
        } catch (error) {
            console.error('Ошибка:', error);
            setErrorMessage('Произошла ошибка. Попробуйте позже.');
        }
    };

    const handleForgotPasswordModal = () => {
        setShowForgotPasswordModal(true);
    };

    const handleCloseForgotPasswordModal = () => {
        setShowForgotPasswordModal(false);
    };

    const handleForgotClick = async () => {
        navigate('/forgot');
    }


    return (
        <div>
            <header className="header header-user">
                <div className="container">
                    <div className="header-contant">
                        <div className="header-logo">
                            <a className="logo-1 logo-user" href="/"><img src={logo3} alt="logo" /></a>
                        </div>
                        <div className="header-nav">

                        </div>
                        <div className="header-button">
                        </div>
                        <div className="blok-menu-mobile">
                        </div>
                    </div>
                </div>
            </header>
            <section className="section-1-user">
                <div className="container">
                    <div className="section-1-user-contant">
                        <div className="form-user-1">
                            <p className="user-form-title">Welcome to Carbon<br/>Learn course</p>
                            <div className="one-ryad">
                                <input className="pole pole-user" name="email" type="email" placeholder="Email" 
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <input className="pole pole-user" name="password" type="text" placeholder="Password" 
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <a className="button button-1 button-popup-1" href="user-dashboard.html">Login</a> */}
                            <button className="button button-1 button-popup-1 " onClick={handleSubmit}>Login</button>
                            <a className="close-popup" href="user-dashboard.html"><img src={burgerImage} alt="close" /></a>
                        </div>
                        <div onClick={handleForgotClick} className="user-link">Forgot password?</div>
                        {
                            errorMessage && <div style={{width: '100%', textAlign: 'center', marginTop: '10px', fontSize: '12px', color: 'red'}}>{errorMessage}</div>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;


/* <div>
            <h2>Вход</h2>
            <input
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
            />
            <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
            />
            <button onClick={handleSubmit}>Войти</button>
            <button onClick={handleForgotPasswordModal}>Забыли пароль?</button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            {/* Модальное окно для восстановления пароля */
// {
//     showForgotPasswordModal && (
//         <div className="modal">
//             <div className="modal-content">
//                 <span className="close" onClick={handleCloseForgotPasswordModal}>&times;</span>
//                 <h2>Восстановление пароля</h2>
//                 <input
//                     type="text"
//                     placeholder="Email"
//                     value={forgotPasswordEmail}
//                     onChange={(e) => setForgotPasswordEmail(e.target.value)}
//                 />
//                 <button onClick={handleForgotPassword}>Восстановить пароль</button>
//             </div>
//         </div>
//     )
// }
//         </div > * /}
