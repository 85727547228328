import React from 'react';
import SettingsImage1 from '../../static/img/settings-image-1.png';
import SettingsImage2 from '../../static/img/settings-image-2.svg';
import progress1 from "../../static/img/class-progress-icon-1.svg"
import ProgressIcon2 from '../../static/img/class-progress-icon-2.svg';
import ProgressIcon3 from '../../static/img/class-progress-icon-3.svg';
import ProgressIcon4 from '../../static/img/class-progress-icon-4.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectFirstName, selectLastName, selectModules, selectUserId, setEmail, setFirstName, setLastName, setSelectedLesson } from '../Login/loginSlice';
import Module from './widgets/Module';
import { updateUserData } from '../Api/course';

function Settings({ setSelectedSection }) {
    const modules = useSelector(selectModules)
    const dispatch = useDispatch()
    const first_name = useSelector(selectFirstName)
    const last_name = useSelector(selectLastName)
    const email = useSelector(selectEmail)
    const userId = useSelector(selectUserId)

    const progressIcons = {
        titleIcon: ProgressIcon3,
        openIcon: progress1,
        closedIcon: ProgressIcon2,
        lessonIcon: ProgressIcon3,
        emptyIcon: ProgressIcon4
    };

    const handleLessonClick = (videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid) => {
        console.log('handleLessonClick', LessonNumber, ModuleNumber, gid)
        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        setSelectedSection('classroom')
        // lesson_number = LessonNumber
        // module_number = ModuleNumber
    };

    const handleFirstNameChange = (e) => {
        dispatch(setFirstName(e.target.value));
    };

    const handleLastNameChange = (e) => {
        dispatch(setLastName(e.target.value))
    };

    const handleEmailChange = (e) => {
        dispatch(setEmail(e.target.value))
    };

    const handleSaveChanges = async () => {
        const success = await updateUserData(email, first_name, last_name, userId);
        if (success) {
            alert('User data updated successfully!');
        } else {
            alert('Failed to update user data.');
        }
    };

    return (
        <>
            <div className="section-class-center">
                <div className="section-class-center-vnutr">
                    <div className="section-class-faq-contant">
                        <p className="faq-title">Personal information</p>
                        <p className="faq-descriptions">Any text about this section of the settings.</p>
                        <div className="settings-blok-image">
                            <img className="settings-img-1" src={SettingsImage1} alt="settings" />
                            <a href="#!">
                                <img className="settings-img-2" src={SettingsImage2} alt="settings" />
                            </a>
                        </div>
                        <form className="settings-blok-input" onSubmit={(e) => { e.preventDefault(); handleSaveChanges(); }}>
                            <input
                                type="text"
                                name="firstname"
                                className="pole-settings"
                                placeholder="First name"
                                value={first_name}
                                onChange={handleFirstNameChange}
                            />
                            <input
                                type="text"
                                name="lastname"
                                className="pole-settings"
                                placeholder="Last name"
                                value={last_name}
                                onChange={handleLastNameChange}
                            />
                            <input
                                type="email"
                                name="email"
                                className="pole-settings"
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <button type="submit" className="button button-header button-settings">
                                Save changes
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="section-class-right section-class-right-settings">
                <p className="section-class-right-title">Progress Lesson</p>

                {modules.map(module => (
                    <Module
                        key={module.id}
                        title={`Module ${module.number}: ${module.name}`}
                        lessons={module.lessons.map(lesson => ({
                            name: lesson.name,
                            videoId: lesson.video_id,
                            videoH: lesson.video_h,
                            number: lesson.number,
                            gid: lesson.gid
                        }))}
                        module_number={module.number}
                        progressIcons={progressIcons}
                        onLessonClick={handleLessonClick}
                    />
                ))}

            </div>
        </>
    );
}

export default Settings;
