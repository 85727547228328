import React, { useEffect, useRef, useState } from 'react';
import ModuleNavigation from './ModuleNavigate';
import progress3 from "../../static/img/class-progress-icon-3.svg"
import progress1 from "../../static/img/class-progress-icon-1.svg"
import ProgressIcon3 from '../../static/img/class-progress-icon-3.svg';
import ProgressIcon4 from '../../static/img/class-progress-icon-4.svg';
import ProgressIcon2 from '../../static/img/class-progress-icon-2.svg';
import Module from './widgets/Module'
import { getCourse, setViewLesson } from '../Api/course'
import Player from '@vimeo/player';
import { useDispatch, useSelector } from 'react-redux';
import { selectLessonNumber, selectModuleNumber, selectModules, selectSelectedLesson, selectUserId, setSelectedLesson } from '../Login/loginSlice';
import VideoPlayer from './VideoPlayer'
import { descriptions_module } from '../../static/texts';

let lesson_number = 0
let module_number = 0
function ClassRoom() {
    const dispatch = useDispatch()
    const user_id = useSelector(selectUserId)
    const modules = useSelector(selectModules);
    const selectedLesson = useSelector(selectSelectedLesson)


    const progressIcons = {
        titleIcon: ProgressIcon3,
        openIcon: progress1,
        closedIcon: ProgressIcon2,
        lessonIcon: ProgressIcon3,
        emptyIcon: ProgressIcon4
    };

    const handleLessonClick = (videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid) => {
        console.log('handleLessonClick', videoId)




        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        lesson_number = LessonNumber
        module_number = ModuleNumber
    };

    const handlePastLesson = () => {
        console.log('past', selectedLesson)
        let LessonNumber = selectedLesson.LessonNumber
        let ModuleNumber = selectedLesson.ModuleNumber
        if (ModuleNumber === 1) {
            if (LessonNumber > 1) LessonNumber = LessonNumber - 1
        }
        else if (ModuleNumber === 2) {
            if (LessonNumber > 1) LessonNumber = LessonNumber - 1
            else {
                ModuleNumber = 1
                LessonNumber = 4
            }
        }
        else {
            if (LessonNumber > 1) LessonNumber = LessonNumber - 1
            else {
                ModuleNumber = 2
                LessonNumber = 3
            }
        }
        const videoId = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_id
        const videoH = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_h
        const moduleName = modules[ModuleNumber - 1].moduleName
        const lessonName = modules[ModuleNumber - 1].lessons[LessonNumber - 1].name
        const gid = modules[ModuleNumber - 1].lessons[LessonNumber - 1].gid
        console.log(modules[ModuleNumber - 1].lessons[LessonNumber])
        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        lesson_number = LessonNumber
        module_number = ModuleNumber
    }

    const handleNextLesson = () => {
        console.log('next', selectedLesson)
        let LessonNumber = selectedLesson.LessonNumber
        let ModuleNumber = selectedLesson.ModuleNumber
        if (ModuleNumber === 2) {
            if (LessonNumber < 3) LessonNumber = LessonNumber + 1
            else {
                ModuleNumber = 3
                LessonNumber = 1
            }
        }
        else {
            if (LessonNumber < 4) LessonNumber = LessonNumber + 1
            else if (ModuleNumber === 1) {
                ModuleNumber = 2
                LessonNumber = 1
            }
        }
        const videoId = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_id
        const videoH = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_h
        const moduleName = modules[ModuleNumber - 1].moduleName
        const lessonName = modules[ModuleNumber - 1].lessons[LessonNumber - 1].name
        const gid = modules[ModuleNumber - 1].lessons[LessonNumber - 1].gid
        console.log(modules[ModuleNumber - 1].lessons[LessonNumber])
        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        lesson_number = LessonNumber
        module_number = ModuleNumber
    }

    return (
        <>
            <div className="section-class-center">
                <div className="section-class-center-vnutr" id="section-class-modul1">

                    <p className="section-class-module"><span>{selectedLesson?.moduleName}</span></p>

                    {!!selectedLesson?.LessonNumber &&
                        <>
                            <p className="section-class-lesson">{`Lesson ${selectedLesson.LessonNumber}: ${selectedLesson?.lessonName}`}</p>
                            <div className="class-1-video">
                                <VideoPlayer
                                    videoId={selectedLesson.videoId}
                                    videoH={selectedLesson.videoH}
                                    lesson_number={lesson_number}
                                    module_number={module_number}
                                    user_id={user_id}
                                />
                                {/* <iframe title='video' className="class-video-1" src={`https://player.vimeo.com/video/942122687?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> */}

                            </div>
                        </>
                    }

                    {!selectedLesson?.LessonNumber &&
                        <>
                            <p className="section-class-center-text-0">Description</p>
                            <p className="section-class-center-text">{descriptions_module[selectedLesson.ModuleNumber]}</p>
                        </>
                    }

                    {!!selectedLesson?.LessonNumber &&
                        <>
                            <p className="section-class-center-text-0">Presentation</p>
                            <iframe title='doc' src={`https://docs.google.com/document/d/${selectedLesson?.gid}/preview`} width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">Загрузка…</iframe>
                            <div className="blok-button-class blok-button-class-2">
                                <div className="button button-header button-class" onClick={handlePastLesson} >Past Lesson</div>
                                <div className="button button-header button-class" onClick={handleNextLesson}  >Next Lesson</div>
                            </div>
                        </>
                    }


                </div>

            </div>
            <div className="section-class-right">
                <p className="section-class-right-title">Progress Lesson</p>

                {modules.map(module => (
                    <Module
                        key={module.id}
                        title={`Module ${module.number}: ${module.name}`}
                        lessons={module.lessons.map(lesson => ({
                            name: lesson.name,
                            videoId: lesson.video_id,
                            videoH: lesson.video_h,
                            number: lesson.number,
                            gid: lesson.gid
                        }))}
                        module_number={module.number}
                        progressIcons={progressIcons}
                        onLessonClick={handleLessonClick}
                    />
                ))}

            </div>
        </>
    );
}

export default ClassRoom;