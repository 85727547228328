import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Person from './components/Person/Person';
import LandingPage from './components/LandingPage';
import Forgot from './components/Login/Forgot';
import ConfirmEmail from './components/Payment/ConfirmEmail';
import PaymentSuccess from './components/Payment/PaymentSuccess';
import PrivacyPolicyModal from './components/PrivacyPolicyModal';
import Terms from './components/Terms';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/forgot"
          element={<Forgot/>}
        />
        <Route
          path="/person"
          element={<Person />}
        />
        <Route
          path="/confirm"
          element={<ConfirmEmail />}
        />
        <Route
          path="/success"
          element={<PaymentSuccess />}
        />
        <Route
          path="/privacy"
          element={<PrivacyPolicyModal />}
        />
        <Route
          path="/terms"
          element={<Terms />}
        />
        <Route
          path="/*"
          element={<LandingPage />}
        />
        <Route
          path="/forbusiness"
          element={<LandingPage business={true}/>}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
