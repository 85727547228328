import React, { useEffect } from "react";
import "./FeedBack.css"; // Добавьте путь к стилям

import arrowPrev from '../../static/img_feedback/arrow-prev.png';
import arrowNext from '../../static/img_feedback/arrow-next.png';
import starIcon from '../../static/img_feedback/icon-stars.png';

import image1 from '../../static/img_feedback/s6-2-image-1.png';
import image2 from '../../static/img_feedback/s6-2-image-2.png';


const FeedbackSection = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "slider.js";
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <section className="section-3 section-7 section-6-1">
                <div className="section-3-contant section-7-contant">
                    <div className="container">
                        <span className="h2-big">Comments and feedback</span>
                        <h2 className="h2 h2-6-1 h2-s3 h2-s7">Comments and feedback</h2>
                    </div>
                    <div className="section-7-items">
                        <div className="swiper-wrap">
                            <div className="swiper swiper-new">
                                <div className="swiper-wrapper swiper-wrapper-new">
                                    <div className="swiper-slide swiper-slide-new">
                                        <div className="swiper-slide-new-vnutr">
                                            <div className="slide-new-header">
                                                <div className="slide-new-header-left">
                                                    <p className="slide-new-text-1">Perez Miram P.</p>
                                                    <p className="slide-new-text-2">a week ago</p>
                                                </div>
                                                <div className="slide-new-header-right">
                                                    <img src={starIcon} alt="star" />
                                                </div>
                                            </div>
                                            <div className="slide-new-body">
                                                Greetings from India! The industry revolving in Carbon is not that well known here. But as my company works with businesses in the EU, we need to be aware of the topic of carbon neutrality. This course helped me with it.
                                                <br /><br />
                                                The course is divided into logical modules which is definitely more convenient.
                                                <br /><br />
                                                Good sound and video quality, there are also some subtitles.
                                                <br /><br />
                                                My recommendation for improvement is: to add exercises for students to apply what we are learning in each module.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-new">
                                        <div className="swiper-slide-new-vnutr">
                                            <div className="slide-new-header">
                                                <div className="slide-new-header-left">
                                                    <p className="slide-new-text-1">Jonn Mayli</p>
                                                    <p className="slide-new-text-2">a 3 day ago</p>
                                                </div>
                                                <div className="slide-new-header-right">
                                                    <img src={starIcon} alt="star" />
                                                </div>
                                            </div>
                                            <div className="slide-new-body">
                                                Great course for deep dive into Carbon Neutrality and Carbon Markets for companies! Easy to follow and understand. Up-to-date information! Special thanks for quality of the picture and audio.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-new">
                                        <div className="swiper-slide-new-vnutr">
                                            <div className="slide-new-header">
                                                <div className="slide-new-header-left">
                                                    <p className="slide-new-text-1">Sergo Gane</p>
                                                    <p className="slide-new-text-2">a 31 day ago</p>
                                                </div>
                                                <div className="slide-new-header-right">
                                                    <img src={starIcon} alt="star" />
                                                </div>
                                            </div>
                                            <div className="slide-new-body">
                                                I did not expect such quality: professional studio recording, subtitles, beautiful picture. In general, the course is well done. Now in substance: I needed to learn what regulations there are in European countries regarding Carbon Neutrality and to know more about Carbon Markets in general.
                                                <br /><br />
                                                The course helped me in this, everything is consistent and structured.
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="swiper-slide swiper-slide-new">
                                    <div className="swiper-slide-new-vnutr">
                                        <div className="slide-new-header">
                                            <div className="slide-new-header-left">
                                                <p className="slide-new-text-1">Jonn M.</p>
                                                <p className="slide-new-text-2">a 3 day ago</p>
                                            </div>
                                            <div className="slide-new-header-right">
                                                <img src={starIcon} alt="star" />
                                            </div>
                                        </div>
                                        <div className="slide-new-body">
                                            Great course for deep dive into Carbon Neutrality and Carbon Markets for companies! Easy to follow and understand. Up-to-date information! Special thanks for quality of the picture and audio.
                                            <br /><br />
                                            The course is divided into logical modules which is definitely more convenient.
                                            <br /><br />
                                            Good sound and video quality, there are also some subtitles.
                                            <br /><br />
                                            My recommendation for improvement is: to add exercises for students to apply what we are learning in each module.
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                            <div className="swiper-button-block">
                                <div className="swiper-button-prev swiper-button-prev-new">
                                    <img src={arrowPrev} alt="Previous" />
                                </div>
                                <div className="swiper-button-next swiper-button-next-new">
                                    <img src={arrowNext} alt="Next" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-3 section-7 section-6-2">
                <div className="section-6-2-contant">
                    <div className="section-6-2-contant-col1">
                        <h2 className="h2 h2-6-2">Certificate</h2>
                        <p className="section-6-2-text">
                            Upon successful completion of the course, you will be issued a certificate
                        </p>
                    </div>
                    <div className="section-6-2-contant-col2">
                        <img className="s6-2-image-1" src={image1} alt="Certificate" />
                    </div>
                    <img className="s6-2-image-2" src={image2} alt="Certificate" />
                </div>
            </section>

        </>

    );
};

export default FeedbackSection;
