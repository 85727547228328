import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_id: 0,
  lesson_number: 0,
  module_number: 0,
  modules: [],
  selected_lesson: {},
  first_name: '',
  last_name: '',
  email: '',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },
    setLessonNumber: (state, action) => {
      state.lesson_number = action.payload;
    },
    setModuleNumber: (state, action) => {
      state.module_number = action.payload;
    },
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setSelectedLesson: (state, action) => {
      state.selected_lesson = action.payload;
    },
    setFirstName: (state, action) => {
      state.first_name = action.payload;
    },
    setLastName: (state, action) => {
      state.last_name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    }
  },
});

export const { setEmail, setUserId, setLessonNumber, setModuleNumber, setModules, setSelectedLesson, setFirstName, setLastName } = loginSlice.actions;

export const selectUserId = (state) => state.login.user_id;
export const selectLessonNumber = (state) => state.login.lesson_number;
export const selectModuleNumber = (state) => state.login.module_number;
export const selectModules = (state) => state.login.modules;
export const selectSelectedLesson = (state) => state.login.selected_lesson;
export const selectFirstName = (state) => state.login.first_name;
export const selectLastName = (state) => state.login.last_name;
export const selectEmail = (state) => state.login.email;

export default loginSlice.reducer;
