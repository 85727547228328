import React, { useState } from 'react';
import '../../static/css/main.css';
import logo3 from '../../static/img/logo-3.svg'
// import oplata_finish from '../../static/img/oplata-finish-img.svg'
import oplata_finish from '../../static/img/material-symbols--id-card.png'

import burger2 from '../../static/img/burger-2.png'

const PaymentSuccess = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);


  const handleOpenChat = () => {
    console.log('handleOpenChat')
    if (window.jivo_api) {
        window.jivo_api.open();
    } else {
        console.error('JivoChat API is not loaded yet.');
    }
};

  const togglePopup = () => {
    console.log('togglePopup')
    handleOpenChat()
    // setPopupOpen(!isPopupOpen);
  };

  return (
    <div>
      <header className="header header-user">
        <div className="container">
          <div className="header-contant">
            <div className="header-logo header-logo-oplata">
              <a className="logo-1 logo-user" href="/"><img src={logo3} alt="logo" /></a>
            </div>
          </div>
        </div>
      </header>

      <section className="section-class">
        <div className="container">
          <div className="section-oplata-finish-contant">
            <img className='oplata_img' src={oplata_finish} alt="oplata" />
            <span className="h2-big h2-big-oplata">Payment confirmed!</span>
            <h1 className="h2 h2-s3 h2-oplata">Payment confirmed!</h1>
            <p className="oplata-contant-description oplata-finish-contant-description">
              Thank you for your payment. An automated payment receipt and access to your personal account will be sent to your registered email.
            </p>
            <a className="button button-header button-oplata-finish" href="/">Back to Home</a>
            <div className="oplata-link open-popup" onClick={togglePopup}>Ask a question</div>
          </div>
        </div>
      </section>

      {isPopupOpen && (
        <div className="popup__bg active">
          <form className="popup active">
            <p className="popup-title">Contact us</p>
            <div className="one-ryad">
              <input className="pole" name="name" type="text" placeholder="Full name" />
              <input className="pole" name="email" type="email" placeholder="Email" />
              <textarea className="pole" name="message" rows="3" placeholder="Message"></textarea>
            </div>
            <button className="button button-1 button-popup-1" type="submit">Submit</button>
            <button className="close-popup" onClick={togglePopup}><img src={burger2} alt="close" /></button>
          </form>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
