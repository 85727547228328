// confirm.js

import { url_back } from "./vars";

async function sendConfirmationEmail(email) {
    try {
        const response = await fetch(url_back + '/confirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            console.log('Confirmation email sent successfully');
        } else {
            console.log('Error sending confirmation email');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

async function sendVerificationCode(email, code) {
    try {
        const response = await fetch(url_back + '/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, code }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Verification code data', data);
            
            return data;
        } else {
            console.log('Error sending verification email');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}


export {sendConfirmationEmail, sendVerificationCode};
